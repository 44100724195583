<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_profile") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateUser"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import languageList from "../i18n/list";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const user = computed(() => store.getters["authentication/getUser"]);

    const formElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "first-name",
        label: "first_name",
        rules: ["isRequired"],
        type: "text",
        value: user.value.firstName,
        variable: "firstName",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "last-name",
        label: "last_name",
        rules: ["isRequired"],
        type: "text",
        value: user.value.lastName,
        variable: "lastName",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "select",
        id: "language",
        label: "language",
        optionLabel: ["name"],
        options: languageList,
        rules: ["isRequired"],
        value: user.value.language,
        variable: "language",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "phone",
        label: "phone",
        rules: ["isRequired", "isPhoneNumber"],
        type: "text",
        value: user.value.phone,
        variable: "phone",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "address",
        label: "address",
        rules: ["isRequired"],
        type: "text",
        value: user.value.address,
        variable: "address",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "postal-code",
        label: "postal_code",
        rules: ["isRequired", "isPostalCode"],
        type: "text",
        value: user.value.postalCode,
        variable: "postalCode",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "city",
        label: "city",
        rules: ["isRequired"],
        type: "text",
        value: user.value.city,
        variable: "city",
      },
      {
        class: "col-span-12 text-right",
        element: "button",
        id: "submit-button",
        label: "save",
        textVariant: "white",
        type: "submit",
        variant: "indigo",
      },
    ]);

    return {
      formElements,
      t,
      updateUser: (data) => store.dispatch("authentication/updateUser", data),
    };
  },
};
</script>
